export var ROUTES = {
    HOME_PAGE: { path: '/' },
    LOGIN_PAGE: { path: '/login' },
    HUB_PAGE: { path: '/hub' },
    ITEMS_PAGE: { path: '/items' },
    CONFIGURATOR_PAGE: { path: '/configurator' },
    ADMIN: { path: '/admin' },
    ADMIN_USERS: { path: '/admin/users' }
};
export var EVENTS = {
    LOGIN_SUCCESS: { route: 'HUB_PAGE' },
    LOGIN_ERROR: { route: 'LOGIN_PAGE' },
    LOGOUT: { route: 'LOGIN_PAGE' },
    CONTEXT_SET: { route: 'CONFIGURATOR_PAGE' }
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ROUTES, EVENTS } from './constants';
import * as singleSpa from 'single-spa';
var RouterService = /** @class */ (function () {
    function RouterService() {
    }
    RouterService.prototype.goTo = function (target, parameters) {
        if (parameters === void 0) { parameters = {}; }
        if (!(target in ROUTES)) {
            throw new Error("No route for target: \"".concat(target, "\""));
        }
        // window.location.href = ROUTES[target].path + this.dictToUri(parameters);
        singleSpa.navigateToUrl(ROUTES[target].path + this.dictToUri(parameters));
    };
    RouterService.prototype.getParams = function (param) {
        var uriDict = this.uriToDict();
        return param ? uriDict[param] : uriDict;
    };
    RouterService.prototype.getPath = function (target) {
        if (!(target in ROUTES)) {
            throw new Error("No route for target: \"".concat(target, "\""));
        }
        return ROUTES[target].path;
    };
    RouterService.prototype.isOn = function (target) {
        if (!(target in ROUTES)) {
            throw new Error("No route for target: \"".concat(target, "\""));
        }
        return window.location.pathname === ROUTES[target].path;
    };
    RouterService.prototype.on = function (event, legacy) {
        if (!(event in EVENTS)) {
            throw new Error("No event: \"".concat(event, "\""));
        }
        else {
            var eventRoute = EVENTS[event].route;
            if (!(eventRoute in ROUTES)) {
                throw new Error("No route: \"".concat(eventRoute, "\" for event: \"").concat(event, "\""));
            }
            else {
                if (legacy) {
                    window.location.href = ROUTES[eventRoute].path;
                }
                else {
                    this.goTo(eventRoute);
                }
            }
        }
    };
    RouterService.prototype.dictToUri = function (dict) {
        var str = [];
        for (var p in dict) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
        }
        return (str.length > 0 ? '?' : '') + str.join("&");
    };
    RouterService.prototype.uriToDict = function () {
        return location.search
            .slice(1)
            .split('&')
            .map(function (p) { return p.split('='); })
            .reduce(function (obj, _a) {
            var _b;
            var key = _a[0], value = _a[1];
            return (__assign(__assign({}, obj), (_b = {}, _b[key] = value, _b)));
        }, {});
    };
    return RouterService;
}());
var routerService = new RouterService();
export default routerService;
